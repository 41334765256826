import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PortfolioBlock from "../../components/PortfolioBlock";
import PageHeaderBlock from "../../components/PageHeaderBlock";
import SeoBlock from "../../components/SeoBlock";

export default function PortfolioPage() {
    const { t } = useTranslation();

    return (
        <Layout>
            <PageHeaderBlock h1="portfolioPageH1" />
            <Seo title={t('portfolioPageWord')} description={t('portfolioSeoDescription')} />
            <PortfolioBlock />
            <SeoBlock text="portfolioSeoText" />
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;