import * as React from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import {Col} from "react-bootstrap";
import {GatsbyImage} from "gatsby-plugin-image";
import { useLocation } from '@reach/router';

export default function PortfolioCard({item, portfolioImage}) {
    const { t } = useTranslation();
    const location = useLocation();
    const isNotThisPage = location.pathname !== `${item.link}/`;

    return (
        <Col md={6} xl={4} className="my-2 my-xl-3">
            <div className={`portfolio__item ${isNotThisPage ? '' : 'this'} d-flex flex-wrap justify-content-between flex-column h-100 px-md-2 px-xl-3 my-xl-3 position-relative`}>
                <div className="portfolio__item-img position-relative">
                    <GatsbyImage image={portfolioImage} alt={item.name}/>
                    {isNotThisPage &&
                        <div className="fake-block position-absolute w-100 h-100 d-none d-xl-flex justify-content-center align-items-center grad">
                            <div className="btn btn__big white-btn text-center text-white">{t('moreWord')}</div>
                        </div>
                    }
                </div>
                {item.name &&
                    <p className="portfolio__item-name h4 text-uppercase mb-0 px-xl-2 px-xxl-3 py-2 pt-xl-1 mt-2 mt-xl-0 text-center mb-xl-2">{item.name}</p>
                }
                {isNotThisPage &&
                    <Link to={item.link} className="fake-block position-absolute w-100 h-100 px-2 d-flex justify-content-center align-items-center" title={item.name} />
                }
            </div>
        </Col>
    )
}