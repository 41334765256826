import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Container} from "react-bootstrap";

export default function SeoBlock({text, variable}) {
    const { t } = useTranslation();

    return (
        <section className="seo-block gray-bg py-2 px-2 px-xl-0">
            <Container className="py-3 py-md-4 bg-white rounded-4 px-3 px-md-4 px-xxl-5 py-xxl-5 my-3 my-xl-4 my-xxl-5">
                {variable ?
                    <div dangerouslySetInnerHTML={{__html: t(text, variable)}}/>
                    :
                    <div dangerouslySetInnerHTML={{__html: t(text)}}/>
                }
            </Container>
        </section>
    )
}