import * as React from "react";
import {useTranslation, Link} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import PortfolioCard from "./portfolio/PortfolioCard";
import {scrollToElem} from "./helpers";

export default function PortfolioBlock({isHome}) {
    const { t } = useTranslation();
    const { allPortfolioDataJson } = useStaticQuery(
        graphql`
            query {
              allPortfolioDataJson {
                nodes {
                  name
                  link
                  src {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          `
    );

    return (
        <Container fluid className="portfolio px-flex position-relative d-inline-block">
            <div className={`my-3 my-xl-4${isHome ? ' my-xxl-5 py-xl-3 py-xxl-4' : ''} py-2`}>
                <Row>
                    {isHome ?
                        <Col xs={12} className="text-center">
                            <h2 className="h1 lined-head mb-3 mb-xl-4">{t('ourPortfolio')}</h2>
                            <p className="mb-3 mb-xxl-4">{t('ourPortfolioLastProjectsText', {projects: 6})}</p>
                        </Col>
                        :
                        <div className="portfolio__scroll-to btn__big white-btn w-100 text-center pointer"
                             dangerouslySetInnerHTML={{__html: t('portfolioScrollTo')}}
                             onClick={() => scrollToElem('.portfolio', 15)}
                        />
                    }
                    {allPortfolioDataJson.nodes.map((item, index) => {
                        const portfolioImage = getImage(item.src);

                        return (
                            isHome && index < 6 ?
                                <PortfolioCard item={item} portfolioImage={portfolioImage} key={index} />
                                : !isHome && <PortfolioCard item={item} portfolioImage={portfolioImage} key={index} />
                        )
                    })}
                    {isHome &&
                        <Col className="d-flex justify-content-center">
                            <Link to={'/portfolio'}
                                  dangerouslySetInnerHTML={{__html: t('portfolioScrollTo')}}
                                  className="btn__big mt-3 mt-md-4 mt-xxl-5"
                            />
                        </Col>
                    }
                </Row>
            </div>
        </Container>
    )
}